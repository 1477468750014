import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSW.png"

function Logistics() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap logiWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap logiOverview">
          <div className="textWrap">
            <h2>Smart logistics</h2>
            <p className="bodyText">
              With the development of the Internet, e-commerce is becoming
              active, and the need to manage the flow of materials quickly and
              accurately from raw materials to processes to deliver finished
              products to consumers is increasing. In particular, the product
              delivery service takes an important place in itself as a measure
              of the company's image. The key to smart logistics is to flexibly
              link information, money flow, etc. in the process of logistics as
              a whole and achieve efficiency, safety, and environment. <br />
              <br />
              The integrated management of data in the ELSA.Cloud SaaS
              <span className="tmText">TM</span>, and employees in the field can
              view and input necessary information directly through a mobile
              application to increase the accuracy/reliability of logistics data
              and check a series of flows at a glance. Build a high-efficiency
              process through logistics and fund flow management and manage
              accurate inventory and delivery.
              <br />
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Logistics
